"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.validationConfig = exports.statusOptions = exports.accountTypeOptions = exports.initialUserUpdateValues = exports.initialUserValues = exports.initialPermissionsValues = exports.featurePermissions = exports.userBranchTableColumns = exports.tableColumns = void 0;
var user_1 = require("../../../../../reducers/slices/user/user");
var constants_1 = require("../../../constants");
exports.tableColumns = [
    {
        displayKey: "name",
        label: "Name",
    },
    {
        displayKey: "surname",
        label: "Surname",
    },
    {
        displayKey: "cell_number",
        label: "Phone Number",
    },
    {
        displayKey: "email",
        label: "Email",
    },
    {
        displayKey: "role",
        label: "Account Type",
    },
    {
        displayKey: "status",
        label: "Status",
        formatter: function (x) {
            if (x) {
                return (0, constants_1.TABLE_STATUSES)(x.status);
            }
        },
    },
];
exports.userBranchTableColumns = [
    {
        displayKey: "branch_number",
        label: "Branch Number",
    },
    {
        displayKey: "branch_name",
        label: "Branch Name",
    },
    {
        displayKey: "phone",
        label: "Phone",
    },
    {
        displayKey: "email",
        label: "Email",
    },
    {
        displayKey: "total_revenue",
        label: "Total Revenue",
    },
    {
        displayKey: "average_monthly_revenue",
        label: "Average Monthly Revenue",
    },
    {
        displayKey: "deliveries",
        label: "Deliveries",
    },
    {
        displayKey: "status",
        label: "Status",
    },
];
exports.featurePermissions = {
    CUSTOMERS: "Customers",
    BRANCHES: "Branches",
    GENERAL_SETTINGS: "General Settings",
    TRADING_SETTINGS: "Trading settings",
    DELIVERY_SETTINGS: "Delivery settings",
    VOUCHERS: "Vouchers",
    SUBSCRIPTIONS: "Subscriptions",
    DEALS: "Deals",
    PACKAGES: "Packages",
    CHANNELS: "Channels",
    INTEGRATIONS: "Integrations",
    WORKFLOWS: "Workflows",
    BRANCH_GROUPS: "Branch Groups",
    BASKETS: "Baskets",
    REDEMPTIONS: "Redemptions",
    DOCUMENTS: "Documents",
    ORDERS: "Orders",
    PARCELS: "Parcels",
    USERS: "Users",
    LOYALTY: "Loyalty",
    PRODUCT_CATALOG: "Product Catalog",
    PRODUCT_CATEGORIES: "Product Categories",
    PRODUCT_ATTRIBUTES: "Product Attributes",
    PRODUCT_FAMILIES: "Product Families",
    PRODUCT_TYPES: "Product Types",
    PAYMENTS: "Payments",
    DRIVERS: "Drivers",
    WAYBILL: "WayBill",
    EXCLUDED_PRODUCT_CATALOG: "Excluded Product Catalog",
    STORE_FRONT: "Store Front",
    CONDIMENTS: "Condiments",
    CAN_SET_DEAL_ONLINE_PURCHASE_ONLY: "Can Set Deal Online Purchase Only",
    CAN_SET_DEAL_APPLY_TO_PROMOTION: "Can Set Deal Apply To Promotion",
    CAN_TOGGLE_DEAL_EXCLUDED_LIST: "Can Toggle Deal Excluded List",
    CAN_SET_DEAL_USAGE: "Can Set Deal Usage",
    CAN_CREATE_PROGRESSIVE_DEAL: "Can Create Progressive Deal",
    MENU_CONCEPTS: "Menu Concepts",
    GIFTING: "Gifting",
    CUSTOMER_SEGMENTS: "Customer Segments",
    ADVANCED_ANALYTICS: "Advanced Analytics",
    CAN_CHANGE_ORDER_TYPE: "Can Change Order Type",
};
var initialPermissionsValues = function (accountType) {
    if (accountType === void 0) { accountType = "ADMIN"; }
    var isAdmin = accountType === "ADMIN";
    var isBranchManager = accountType === "BRANCH_MANAGER";
    var isManager = accountType === "AREA_MANAGER" || isBranchManager;
    var featureConfig = {
        read: isAdmin,
        create: isAdmin,
        update: isAdmin,
        delete: isAdmin,
    };
    var permissions = {
        CUSTOMERS: !isBranchManager ? __assign(__assign({}, featureConfig), { read: true }) : featureConfig,
        BRANCHES: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        GENERAL_SETTINGS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        TRADING_SETTINGS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        DELIVERY_SETTINGS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        VOUCHERS: featureConfig,
        SUBSCRIPTIONS: featureConfig,
        DEALS: featureConfig,
        PACKAGES: featureConfig,
        USERS: featureConfig,
        CHANNELS: featureConfig,
        INTEGRATIONS: featureConfig,
        WORKFLOWS: featureConfig,
        BRANCH_GROUPS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        BASKETS: isManager ? __assign(__assign({}, featureConfig), { read: true }) : featureConfig,
        REDEMPTIONS: isManager ? __assign(__assign({}, featureConfig), { read: true }) : featureConfig,
        ORDERS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        PARCELS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        PRODUCT_CATALOG: featureConfig,
        PRODUCT_CATEGORIES: featureConfig,
        PRODUCT_ATTRIBUTES: featureConfig,
        PRODUCT_FAMILIES: featureConfig,
        PRODUCT_TYPES: featureConfig,
        DOCUMENTS: !isBranchManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        LOYALTY: !isBranchManager ? __assign(__assign({}, featureConfig), { read: true }) : featureConfig,
        PAYMENTS: isBranchManager ? featureConfig : { read: true, create: true, update: true, delete: true },
        DRIVERS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        WAYBILL: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        EXCLUDED_PRODUCT_CATALOG: featureConfig,
        STORE_FRONT: featureConfig,
        CONDIMENTS: featureConfig,
        CAN_SET_DEAL_ONLINE_PURCHASE_ONLY: isManager ? __assign(__assign({}, featureConfig), { read: true, create: false, delete: false }) : featureConfig,
        CAN_SET_DEAL_APPLY_TO_PROMOTION: isManager ? __assign(__assign({}, featureConfig), { read: true, create: false, delete: false }) : featureConfig,
        CAN_TOGGLE_DEAL_EXCLUDED_LIST: isManager ? __assign(__assign({}, featureConfig), { read: true, create: false, delete: false }) : featureConfig,
        CAN_SET_DEAL_USAGE: isManager ? __assign(__assign({}, featureConfig), { read: true, create: false, delete: false }) : featureConfig,
        CAN_CREATE_PROGRESSIVE_DEAL: isManager ? __assign(__assign({}, featureConfig), { read: true, create: false, delete: false }) : featureConfig,
        MENU_CONCEPTS: isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        GIFTING: !isManager ? __assign(__assign({}, featureConfig), { read: true, update: true }) : featureConfig,
        ADVANCED_ANALYTICS: __assign(__assign({}, featureConfig), { read: true, update: true }),
        CUSTOMER_SEGMENTS: featureConfig,
        CAN_CHANGE_ORDER_TYPE: isManager ? __assign(__assign({}, featureConfig), { read: true, create: false, delete: false }) : featureConfig,
    };
    return permissions;
};
exports.initialPermissionsValues = initialPermissionsValues;
exports.initialUserValues = {
    name: user_1.devMode ? "John" : "",
    surname: user_1.devMode ? "Doe" : "",
    password: user_1.devMode ? "12345" : "",
    user_name: user_1.devMode ? "John" : "",
    cell_number: user_1.devMode ? "123-456-7890" : "",
    email: user_1.devMode ? "johndoe@example.com" : "",
    role: "ADMIN",
    status: user_1.devMode ? "ACTIVE" : "",
    manager_of: [],
    user_permissions: (0, exports.initialPermissionsValues)(),
    get_communication: false,
    agrees_terms_and_conditions: true,
};
// There's probably a better way to approach this, but we
// need to somehow make provisions for users that were previously
// created without fields like user_permissions
exports.initialUserUpdateValues = {
    status: "ACTIVE",
    manager_of: [],
    user_permissions: (0, exports.initialPermissionsValues)(),
};
exports.accountTypeOptions = [
    { label: "Admin", value: "ADMIN" },
    { label: "Area Manager", value: "AREA_MANAGER" },
    { label: "Branch Manager", value: "BRANCH_MANAGER" },
];
exports.statusOptions = [
    { label: "Active", value: "ACTIVE" },
    { label: "Inactive", value: "INACTIVE" },
];
exports.validationConfig = {
    manager_of: { dependOn: { key: "role", value: ["BRANCH_MANAGER", "AREA_MANAGER"] } },
};

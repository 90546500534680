"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTablePagination = exports.setIsLoading = exports.setSelectedProductAttributeFamily = exports.setProductAttributeFamilies = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../../constants/pages");
var initialState = {
    productAttributeFamilies: [],
    selectedProductAttributeFamily: null,
    pagination: pages_1.initialPaginationValues,
    loading: false,
    error: null,
};
var productAttributeFamilySlice = (0, toolkit_1.createSlice)({
    name: 'productAttributeFamily',
    initialState: initialState,
    reducers: {
        setProductAttributeFamilies: function (state, action) {
            state.productAttributeFamilies = action.payload;
        },
        setSelectedProductAttributeFamily: function (state, action) {
            state.selectedProductAttributeFamily = action.payload;
        },
        setIsLoading: function (state, action) {
            state.loading = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setProductAttributeFamilies = (_a = productAttributeFamilySlice.actions, _a.setProductAttributeFamilies), exports.setSelectedProductAttributeFamily = _a.setSelectedProductAttributeFamily, exports.setIsLoading = _a.setIsLoading, exports.setTablePagination = _a.setTablePagination;
exports.default = productAttributeFamilySlice.reducer;

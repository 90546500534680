"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAttributeFamilyTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var tabel_cell_1 = require("../../../../../components/tabel_cell");
var table_bottom_content_1 = require("../../../../../components/table/tableBottomContent/table_bottom_content");
var tableContext_1 = require("../../../../../contexts/tableContext");
var get_product_attribute_families_thunk_1 = require("../../../../../reducers/slices/products/product_attribute_family/thunks/get_product_attribute_families_thunk");
var useColumns_1 = require("./hooks/useColumns");
var useFilterActions_1 = require("./hooks/useFilterActions");
var useRenderCellViews_1 = require("./hooks/useRenderCellViews");
var useRowActions_1 = require("./hooks/useRowActions");
var table_top_bar_1 = require("./table_top_bar");
var table_top_content_1 = require("./table_top_content");
var ProductAttributeFamilyTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.productAttributeFamilyState; }), pagination = _a.pagination, productAttributeFamilies = _a.productAttributeFamilies, loading = _a.loading;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var filterActions = (0, useFilterActions_1.useFilterActions)({}).filterActions;
    var _b = (0, useRowActions_1.useRowActions)(), rowActions = _b.rowActions, onSelectionChange = _b.onSelectionChange, selectedTableCells = _b.selectedTableCells;
    var renderCellViews = (0, useRenderCellViews_1.useRenderCellViews)().renderCellViews;
    var _c = (0, useColumns_1.useColumns)(), columns = _c.columns, excludeSortColumns = _c.excludeSortColumns, headerColumns = _c.headerColumns, initialSortDescriptor = _c.initialSortDescriptor, onVisibleColumnsChanged = _c.onVisibleColumnsChanged, setTableColumnConfig = _c.setTableColumnConfig, tableColumnConfig = _c.tableColumnConfig, visibleColumns = _c.visibleColumns;
    var getProductAttributeFamilies = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var sort_by = _a.sort_by, order_by = _a.order_by, query = __rest(_a, ["sort_by", "order_by"]);
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_product_attribute_families_thunk_1.get_product_attribute_families_thunk)(query))];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (client_token && !loading) {
            getProductAttributeFamilies(__assign({}, pagination));
        }
    }, [client_token]);
    return ((0, jsx_runtime_1.jsx)(tableContext_1.TableContextProvider, __assign({ columns: columns, defaultQueryParams: pagination, excludeSortColumns: excludeSortColumns, filterActions: filterActions, headerColumns: headerColumns, initialSortDescriptor: initialSortDescriptor, onPageChange: getProductAttributeFamilies, onVisibleColumnsChanged: onVisibleColumnsChanged, setTableConfig: setTableColumnConfig, tableColumnConfig: tableColumnConfig, pagination: pagination, visibleColumns: visibleColumns }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col" }, { children: [(0, jsx_runtime_1.jsx)(table_top_bar_1.TableTopBar, {}), (0, jsx_runtime_1.jsxs)(react_2.Table, __assign({ isHeaderSticky: true, "aria-label": "Products Attribute Family", bottomContent: (0, jsx_runtime_1.jsx)(table_bottom_content_1.TableBottomContent, {}), bottomContentPlacement: "outside", classNames: {
                        td: "before:bg-transparent",
                    }, className: "overflow-hidden", selectedKeys: selectedTableCells, selectionMode: "multiple", sortDescriptor: initialSortDescriptor, topContent: (0, jsx_runtime_1.jsx)(table_top_content_1.TableTopContent, { selectedTableCells: [] }), topContentPlacement: "outside", onSelectionChange: onSelectionChange }, { children: [(0, jsx_runtime_1.jsx)(react_2.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_2.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_2.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_2.TableBody, __assign({ isLoading: loading, emptyContent: "This table is empty.", items: productAttributeFamilies, loadingContent: (0, jsx_runtime_1.jsx)(react_2.Spinner, { label: "Loading..." }) }, { children: function (item) {
                                return ((0, jsx_runtime_1.jsx)(react_2.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_2.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                            } }))] }))] })) })));
};
exports.ProductAttributeFamilyTablePage = ProductAttributeFamilyTablePage;

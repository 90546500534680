"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductAttributeFamilyPage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var console_1 = require("../../../../../reducers/slices/console");
var product_attribute_family_table_1 = require("./product_attribute_family_table");
var ProductAttributeFamilyPage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    (0, react_1.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Product Families"));
    }, []);
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Routes, { children: (0, jsx_runtime_1.jsx)(react_router_dom_1.Route, { path: "/*", element: (0, jsx_runtime_1.jsx)(product_attribute_family_table_1.ProductAttributeFamilyTablePage, {}) }) }) }));
};
exports.ProductAttributeFamilyPage = ProductAttributeFamilyPage;

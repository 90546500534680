"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useFilterActions = void 0;
var react_1 = require("react");
function useFilterActions(_a) {
    var filterActions = (0, react_1.useMemo)(function () { return [{}]; }, []);
    var resetFilters = (0, react_1.useCallback)(function () {
    }, []);
    (0, react_1.useEffect)(function () {
        resetFilters();
    }, []);
    return (0, react_1.useMemo)(function () { return ({
        filterActions: filterActions,
    }); }, [filterActions]);
}
exports.useFilterActions = useFilterActions;

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.CONSOLE_PRODUCT_CONFIGURATIONS_TABLE_CONFIG = exports.CONSOLE_ORDER_VARIANTS_TABLE_CONFIG = exports.CONSOLE_ORDER_PRODUCTS_TABLE_CONFIG = exports.CONSOLE_MULTI_RADII_TABLE_CONFIG = exports.CONSOLE_EXCLUSION_AREAS_TABLE_CONFIG = exports.CONSOLE_DELIVERY_HOURS_TABLE_CONFIG = exports.CONSOLE_QUOTES_TABLE_CONFIG = exports.CONSOLE_ORDERS_TABLE_CONFIG = exports.CONSOLE_BASKETS_TABLE_CONFIG = exports.TABLE_COLUMN_CONFIG = exports.VIEWED_ORDER_IDS_STORAGE = exports.REDIRECT_ROUTE = exports.NEW_CHANNEL_LOCAL_STORAGE = exports.HERO_CONNECT_USER_LOCAL_STORAGE = exports.USER_LOCAL_STORAGE = void 0;
exports.USER_LOCAL_STORAGE = "user_local_storage";
exports.HERO_CONNECT_USER_LOCAL_STORAGE = "hero_connect_user_local_storage";
exports.NEW_CHANNEL_LOCAL_STORAGE = "new_channel_local_storage";
exports.REDIRECT_ROUTE = "redirect_route";
exports.VIEWED_ORDER_IDS_STORAGE = "viewed_order_ids_storage";
exports.TABLE_COLUMN_CONFIG = "table_column_config";
exports.CONSOLE_BASKETS_TABLE_CONFIG = "console_baskets_table_config";
exports.CONSOLE_ORDERS_TABLE_CONFIG = "console_orders_table_config";
exports.CONSOLE_QUOTES_TABLE_CONFIG = "console_quotes_table_config";
exports.CONSOLE_DELIVERY_HOURS_TABLE_CONFIG = "console_delivery_hours_table_config";
exports.CONSOLE_EXCLUSION_AREAS_TABLE_CONFIG = "console_exclusion_areas_table_config";
exports.CONSOLE_MULTI_RADII_TABLE_CONFIG = "console_multi_radii_table_config";
exports.CONSOLE_ORDER_PRODUCTS_TABLE_CONFIG = "console_order_products_table_config";
exports.CONSOLE_ORDER_VARIANTS_TABLE_CONFIG = "console_order_variants_table_config";
exports.CONSOLE_PRODUCT_CONFIGURATIONS_TABLE_CONFIG = "console_product_configurations_table_config";

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.columns = exports.INITIAL_VISIBLE_COLUMNS = void 0;
exports.INITIAL_VISIBLE_COLUMNS = [
    "name",
    "description",
    "type",
    "actions"
];
exports.columns = [
    { name: "Name", uid: "name", sortable: true },
    { name: "Description", uid: "description", sortable: true },
    { name: "Type", uid: "type", sortable: true },
    { name: "Actions", uid: "actions", sortable: false }
];

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.ProductConfigurationTablePage = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var get_channels_1 = require("../../../../../api/calls/shopify/get_channels");
var routes_1 = require("../../../../../constants/routes");
var main_1 = require("../../../../../reducers/slices/main");
var product_configuration_1 = require("../../../../../reducers/slices/products/product_configuration/product_configuration");
var get_product_configurations_thunk_1 = require("../../../../../reducers/slices/products/product_configuration/thunks/get_product_configurations_thunk");
var ProductConfigurationTablePage = function () {
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.productConfigurationState; }), pagination = _a.pagination, productConfigurations = _a.productConfigurations, loading = _a.loading;
    var client_token = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).client_token;
    var _b = (0, react_1.useState)([]), activeChannels = _b[0], setActiveChannels = _b[1];
    var getProductConfigurations = function (_a) { return __awaiter(void 0, void 0, void 0, function () {
        var sort_by = _a.sort_by, order_by = _a.order_by, query = __rest(_a, ["sort_by", "order_by"]);
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0: return [4 /*yield*/, dispatch((0, get_product_configurations_thunk_1.get_product_configurations_thunk)(query))];
                case 1: return [2 /*return*/, _b.sent()];
            }
        });
    }); };
    var channels = activeChannels.filter(function (channel) { return channel.name !== "All"; });
    var fetchChannels = function () { return __awaiter(void 0, void 0, void 0, function () {
        var response, seenTypes_1, activeChannels_1, err_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, get_channels_1.get_channels)({})];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        seenTypes_1 = new Set();
                        activeChannels_1 = response.content.channel_list
                            .filter(function (_a) {
                            var type = _a.type;
                            if (seenTypes_1.has(type.toLowerCase()))
                                return false;
                            seenTypes_1.add(type.toLowerCase());
                            return true;
                        })
                            .map(function (_a) {
                            var type = _a.type, status = _a.status;
                            var config = productConfigurations.find(function (config) { return config.channel.toLowerCase() === type.toLowerCase(); });
                            return __assign({ name: type, channel: type.toUpperCase(), status: status }, config);
                        });
                        setActiveChannels(activeChannels_1);
                    }
                    else {
                        throw new response.message();
                    }
                    return [3 /*break*/, 3];
                case 2:
                    err_1 = _a.sent();
                    dispatch((0, main_1.setMessageModal)({
                        title: "Something went wrong when retrieving the product completeness.",
                        messages: ["Failed to fetch product completeness."],
                    }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    (0, react_1.useEffect)(function () {
        if (client_token) {
            getProductConfigurations(__assign({}, pagination));
        }
    }, [client_token]);
    (0, react_1.useEffect)(function () {
        if (!!productConfigurations.length) {
            fetchChannels();
        }
    }, [productConfigurations]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "mx-auto" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex w-[400px] items-center gap-2 mb-6" }, { children: [(0, jsx_runtime_1.jsx)("h1", __assign({ className: "text-2xl font-[700] leading-[32px]" }, { children: "Channels Configurations" })), (0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "hidden items-center text-default-500 sm:flex", size: "sm", variant: "flat" }, { children: channels.length }))] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4" }, { children: channels.map(function (channel, i) {
                    return ((0, jsx_runtime_1.jsxs)(react_2.Card, __assign({ className: "hover:shadow-lg transition-shadow" }, { children: [(0, jsx_runtime_1.jsxs)(react_2.CardHeader, __assign({ className: "text-lg font-medium flex flex-row items-center justify-between space-y-0 pb-2 capitalize" }, { children: [channel.name, (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", variant: "solid", startContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faArrowRight }), onPress: function () {
                                            dispatch((0, product_configuration_1.setSelectedProductConfiguration)(channel));
                                            if (channel === null || channel === void 0 ? void 0 : channel._id) {
                                                return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_CONFIGURATION_DETAILS_ROUTE, "/").concat(channel._id));
                                            }
                                            navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_CONFIGURATION_DETAILS_ROUTE));
                                        } }, { children: (channel === null || channel === void 0 ? void 0 : channel._id) ? "View" : "Create" }))] })), (0, jsx_runtime_1.jsx)(react_2.Divider, {}), (0, jsx_runtime_1.jsx)(react_2.CardBody, { children: (0, jsx_runtime_1.jsxs)("span", __assign({ className: "inline-flex items-center rounded-full py-0.5 text-xs font-medium bg-green-100 text-green-800" }, { children: ["Status: ", channel.status] })) })] }), i));
                }) })), channels.length === 0 && (0, jsx_runtime_1.jsx)("div", __assign({ className: "mt-5 content-center text-center text-lg" }, { children: "No channels found." }))] })));
};
exports.ProductConfigurationTablePage = ProductConfigurationTablePage;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_2 = require("@nextui-org/react");
var remove_quote_1 = require("../../../../../../api/calls/customer-quotes/remove/remove_quote");
var routes_1 = require("../../../../../../constants/routes");
var main_1 = require("../../../../../../reducers/slices/main");
var doshx_controls_web_1 = require("doshx_controls_web");
var get_client_quotes_thunk_1 = require("../../../../../../reducers/slices/quotes/thunks/get_client_quotes_thunk");
function useRowActions() {
    var _this = this;
    var dispatch = (0, react_redux_1.useDispatch)();
    var navigate = (0, react_router_dom_1.useNavigate)();
    var pagination = (0, react_redux_1.useSelector)(function (state) { return state.quoteState; }).pagination;
    var user = (0, react_redux_1.useSelector)(function (state) { return state.userState; }).user;
    var deleteRef = (0, react_1.useRef)(null);
    var viewRef = (0, react_1.useRef)(null);
    var getDeleteProps = (0, react_2.useButton)({ ref: deleteRef }).getButtonProps;
    var getViewProps = (0, react_2.useButton)({ ref: viewRef }).getButtonProps;
    var deleteAction = (0, react_1.useCallback)(function (entry) { return function () {
        var _a, _b;
        dispatch((0, main_1.setContentModal)({
            title: "Confirm",
            content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.LabelControl, { label: "Are you sure you want to remove ".concat((_a = entry === null || entry === void 0 ? void 0 : entry.customer) === null || _a === void 0 ? void 0 : _a.name, "  ").concat((_b = entry === null || entry === void 0 ? void 0 : entry.customer) === null || _b === void 0 ? void 0 : _b.surname, "'s quote ?"), textColor: "text-inputText" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Cancel", size: "small", backgroundColor: "bg-slate-300", textColor: "text-dark", onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }), (0, jsx_runtime_1.jsx)(doshx_controls_web_1.ButtonControl, { label: "Continue", size: "small", onClick: function () { return __awaiter(_this, void 0, void 0, function () {
                                    var response;
                                    return __generator(this, function (_a) {
                                        switch (_a.label) {
                                            case 0: return [4 /*yield*/, (0, remove_quote_1.remove_customer_quote)({
                                                    _id: entry === null || entry === void 0 ? void 0 : entry._id,
                                                    deleted_by: "".concat(user === null || user === void 0 ? void 0 : user.name, " ").concat(user === null || user === void 0 ? void 0 : user.surname),
                                                })];
                                            case 1:
                                                response = _a.sent();
                                                if (response.success) {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Success...", messages: [response.message] }));
                                                    dispatch((0, main_1.setContentModal)(null));
                                                    dispatch((0, get_client_quotes_thunk_1.get_client_quotes_thunk)(__assign({}, pagination)));
                                                }
                                                else {
                                                    dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when removing the quote?", messages: [response.message] }));
                                                }
                                                dispatch((0, main_1.setContentModal)(null));
                                                return [2 /*return*/];
                                        }
                                    });
                                }); } })] }))] }))),
        }));
    }; }, [pagination, user]);
    var viewAction = (0, react_1.useCallback)(function (entry) { return function () {
        navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.QUOTE_DETAILS_ROUTE, "/").concat(entry === null || entry === void 0 ? void 0 : entry._id));
    }; }, [navigate]);
    var rowActions = (0, react_1.useCallback)(function (row) { return [
        {
            element: ((0, jsx_runtime_1.jsx)(react_2.Tooltip, __assign({ className: "text-foreground", content: "View Details" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, __assign({}, getViewProps(), { onClick: viewAction(row), className: "cursor-pointer text-default-400", height: 18, icon: pro_solid_svg_icons_1.faEye, width: 18 })) }))),
            key: "delete",
        },
        {
            element: ((0, jsx_runtime_1.jsx)(react_2.Tooltip, __assign({ className: "text-foreground", content: "Delete Quote" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, __assign({}, getDeleteProps(), { onClick: deleteAction(row), className: "cursor-pointer text-default-400", height: 18, icon: pro_solid_svg_icons_1.faTrash, width: 18 })) }))),
            key: "delete",
        },
    ]; }, [getDeleteProps, getViewProps]);
    return (0, react_1.useMemo)(function () { return ({
        rowActions: rowActions,
    }); }, [rowActions]);
}
exports.useRowActions = useRowActions;

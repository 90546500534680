"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var _a, _b;
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = exports.PriceCellView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var doshx_controls_web_1 = require("doshx_controls_web");
var formatting_1 = require("lets-trade-utils/lib/utils/formatting");
var react_redux_1 = require("react-redux");
var channelsCellComponent_1 = require("../../../../../../components/channelsCellComponent");
var copy_text_1 = require("../../../../../../components/copy_text");
var StatusCategory;
(function (StatusCategory) {
    StatusCategory["SUCCESS"] = "SUCCESS";
    StatusCategory["DEFAULT"] = "DEFAULT";
    StatusCategory["SECONDARY"] = "SECONDARY";
    StatusCategory["DANGER"] = "DANGER";
    StatusCategory["WARNING"] = "WARNING";
})(StatusCategory || (StatusCategory = {}));
var STATUS_COLOR_MAP = (_a = {},
    _a[StatusCategory.SUCCESS] = "success",
    _a[StatusCategory.DEFAULT] = "default",
    _a[StatusCategory.SECONDARY] = "secondary",
    _a[StatusCategory.DANGER] = "danger",
    _a[StatusCategory.WARNING] = "warning",
    _a);
var STATUS_LISTS = (_b = {},
    _b[StatusCategory.SUCCESS] = [
        "ACTIVE",
        "COMPLETED",
        "FULFILLED",
        "PROCESSED",
        "ORDERED",
        "READY",
        "ARRIVED",
        "DELIVERED",
        "ONLINE",
        "AVAILABLE",
        "COMPLETE",
        "SUCCESS",
        "ACCEPTED",
    ],
    _b[StatusCategory.DEFAULT] = ["INACTIVE", "CANCELLED", "OFFLINE"],
    _b[StatusCategory.SECONDARY] = ["UPDATED", "OPEN", "RETURNED", "PACKED", "VIEWED"],
    _b[StatusCategory.DANGER] = ["SUSPENDED", "ABANDONED", "RETURNED-BY-CUSTOMER", "DECLINED", "EXPIRED"],
    _b[StatusCategory.WARNING] = [
        "AWAITING-SIGNUP",
        "PROCESSING",
        "CHECKED-OUT",
        "POS-ORDERED",
        "COLLECTED",
        "DELIVERY-READY",
        "PARTIALLY-DELIVERED",
        "AWAITING-EMAIL-CONFIRMATION",
        "SENT",
        "PENDING",
    ],
    _b);
function getStatusColor(status) {
    for (var _i = 0, _a = Object.entries(STATUS_LISTS); _i < _a.length; _i++) {
        var _b = _a[_i], category = _b[0], statuses = _b[1];
        if (statuses.includes(status.toUpperCase())) {
            return STATUS_COLOR_MAP[category];
        }
    }
    return "default";
}
function PriceCellView(_a) {
    var _b;
    var cellValue = _a.cellValue, client = _a.client;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: (0, formatting_1.formatMoney)({
            symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
            value: (_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _b !== void 0 ? _b : "0",
            symbolSeparation: true,
        }) })));
}
exports.PriceCellView = PriceCellView;
function StatusCellView(_a) {
    var cellValue = _a.cellValue;
    var color = (0, react_1.useMemo)(function () { return getStatusColor(cellValue.toString()); }, [cellValue]);
    return ((0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "text-nowrap text-xs capitalize text-default-foreground", color: color, size: "md", variant: "flat" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-1" }, { children: cellValue })) })));
}
function CompletenessCellView(_a) {
    var _b;
    var cellValue = _a.cellValue;
    var percentage = Number((_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.completeness) === null || _b === void 0 ? void 0 : _b.total) || 0;
    var color = (0, react_1.useMemo)(function () {
        if (percentage >= 90)
            return "success";
        if (percentage >= 70)
            return "warning";
        return "danger";
    }, [percentage]);
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-row items-center gap-2" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Progress, { color: color, value: percentage, radius: "full", size: "sm" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "text-xs text-default-foreground" }, { children: [percentage.toFixed(0), "%"] }))] })));
}
function useRenderCellViews() {
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        var _a, _b, _c;
        var commonTextStyles = "text-small capitalize text-default-foreground";
        var nowrapTextStyles = "text-nowrap text-small capitalize text-default-foreground";
        return [
            {
                key: "image_url",
                content: ((0, jsx_runtime_1.jsx)(react_2.User, { avatarProps: {
                        radius: "lg",
                        src: cellValue,
                    }, classNames: {
                        name: "text-default-foreground",
                        description: "text-default-500",
                    }, name: "" })),
            },
            {
                key: "category",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: commonTextStyles }, { children: (_a = cellValue === null || cellValue === void 0 ? void 0 : cellValue.name) !== null && _a !== void 0 ? _a : "N/A" })),
            },
            {
                key: "pid",
                content: (0, jsx_runtime_1.jsx)(copy_text_1.CopyText, { children: cellValue }),
            },
            {
                key: "completeness",
                content: (0, jsx_runtime_1.jsx)(CompletenessCellView, { cellValue: cellValue }),
            },
            {
                key: "on_sale_price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: commonTextStyles }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _b !== void 0 ? _b : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "price",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: doshx_controls_web_1.Utilities.formatMoney({
                        symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
                        value: (_c = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _c !== void 0 ? _c : "0",
                        symbolSeparation: true,
                    }) }))),
            },
            {
                key: "on_sale",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: nowrapTextStyles }, { children: cellValue ? "Yes" : "No" })),
            },
            {
                key: "channels",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "float-start flex gap-1" }, { children: (0, channelsCellComponent_1.ChannelsCellComponent)({ channels: cellValue }) })),
            },
            {
                key: "status",
                content: (0, jsx_runtime_1.jsx)(StatusCellView, { cellValue: cellValue }),
            },
        ];
    }, [client]);
    return (0, react_1.useMemo)(function () { return ({ renderCellViews: renderCellViews }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var PimChannelsAndAppsConfigComponent = function (props) {
    var values = props.values, canPublish = props.canPublish, updateValues = props.updateValues;
    var STATUSES = [
        canPublish && {
            label: "Available",
            value: "AVAILABLE",
        },
        canPublish && { label: "Out of stock", value: "OUT-OF-STOCK" },
        { label: "Draft", value: "DRAFT" },
    ].filter(Boolean);
    return ((0, jsx_runtime_1.jsxs)(react_1.Card, __assign({ className: "w-full" }, { children: [(0, jsx_runtime_1.jsx)(react_1.CardHeader, __assign({ className: "flex justify-between items-center px-6 py-4" }, { children: (0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-lg font-semibold" }, { children: "Status" })) })), (0, jsx_runtime_1.jsx)(react_1.Divider, {}), (0, jsx_runtime_1.jsx)(react_1.CardBody, __assign({ className: "gap-6 p-6" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col lg:flex-row gap-4 justify-between" }, { children: (0, jsx_runtime_1.jsx)(react_1.Select, __assign({ label: "Status", placeholder: "Select a status", className: "max-w-xs", variant: "flat", isRequired: true, selectedKeys: (values === null || values === void 0 ? void 0 : values.status) ? [values.status] : [STATUSES[STATUSES.length - 1].value], onChange: function (e) {
                            updateValues("status", e.target.value);
                        }, errorMessage: !(values === null || values === void 0 ? void 0 : values.status) ? "Please select a status" : "", classNames: {
                            trigger: "border-borders",
                        }, size: "md" }, { children: STATUSES.map(function (status) { return ((0, jsx_runtime_1.jsx)(react_1.SelectItem, __assign({ value: status.value }, { children: status.label }), status.value)); }) })) })) }))] })));
};
exports.default = PimChannelsAndAppsConfigComponent;

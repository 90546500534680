"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
var react_1 = require("react");
var IFilterAction_1 = require("../../../../../../interfaces/IFilterAction");
var useProductAttributeFilter = function (_a) {
    var productAttributes = _a.productAttributes;
    var _b = (0, react_1.useState)({}), filterValues = _b[0], setFilterValues = _b[1];
    var handleFilterChange = function (value) {
        setFilterValues(function (prevValues) { return (__assign(__assign({}, prevValues), value)); });
    };
    var filterOptions = (0, react_1.useMemo)(function () {
        return productAttributes.map(function (attr) { return (__assign(__assign({ key: "attributes[".concat(attr === null || attr === void 0 ? void 0 : attr.name, "]"), label: attr === null || attr === void 0 ? void 0 : attr.name, description: attr === null || attr === void 0 ? void 0 : attr.description, type: determineFilterType(attr), value: filterValues[attr === null || attr === void 0 ? void 0 : attr._id], onChange: function (val) {
                var _a;
                var _b;
                return handleFilterChange((_a = {}, _a[attr === null || attr === void 0 ? void 0 : attr._id] = ((_b = val === null || val === void 0 ? void 0 : val.target) === null || _b === void 0 ? void 0 : _b.value) || val, _a));
            }, defaultOpen: true }, ((attr === null || attr === void 0 ? void 0 : attr.options) && {
            options: attr === null || attr === void 0 ? void 0 : attr.options.map(function (option) { return (__assign({ value: option, label: option }, ((attr === null || attr === void 0 ? void 0 : attr.name.toLowerCase()) === "color" && {
                color: option.toLowerCase(),
            }))); }),
        })), ((attr === null || attr === void 0 ? void 0 : attr.type) === "number" && {
            range: { min: 0, max: attr.default_value, step: 1, defaultValue: attr.default_value },
        }))); });
    }, [productAttributes, filterValues]);
    return {
        filterOptions: filterOptions,
    };
};
exports.default = useProductAttributeFilter;
var determineFilterType = function (attr) {
    if (attr.name.toLowerCase() === "color") {
        return IFilterAction_1.FilterTypeEnum.Color;
    }
    if ((attr.options && attr.options.length > 0) || attr.type === "LIST") {
        return IFilterAction_1.FilterTypeEnum.CheckboxGroup;
    }
    if (attr.type === "NUMBER") {
        return IFilterAction_1.FilterTypeEnum.PriceRange;
    }
    if (attr.type === "RADIO" || attr.type === "BOOLEAN") {
        return IFilterAction_1.FilterTypeEnum.RadioGroup;
    }
    if (attr.type === "TEXT") {
        return IFilterAction_1.FilterTypeEnum.Input;
    }
    return IFilterAction_1.FilterTypeEnum.Tabs;
};

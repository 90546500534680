"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.channelOptions = exports.validationConfig = exports.initialProductConfigurationValues = exports.baseFields = exports.columns = exports.INITIAL_VISIBLE_COLUMNS = void 0;
exports.INITIAL_VISIBLE_COLUMNS = ["name", "channel", "actions"];
exports.columns = [
    { name: "Name", uid: "name", sortable: true },
    { name: "Channel", uid: "channel", sortable: true },
    { name: "Actions", uid: "actions", sortable: false },
];
exports.baseFields = [
    { key: 'quantity', label: 'Quantity' },
    { key: 'quantity_type', label: 'Quantity Type' },
    { key: 'cost_price', label: 'Cost Price' },
    { key: 'on_promotion', label: 'On Promotion' },
    { key: 'promotion_price', label: 'Promotion Price' },
    { key: 'vatable', label: 'Vatable' },
    { key: 'track_quantity', label: 'Track Quantity' },
    { key: 'notify_when_stock_low', label: 'Notify When Stock Low' },
    { key: 'continue_selling_when_out_of_stock', label: 'Continue Selling When Out Of Stock' },
    { key: 'category_id', label: 'Category ID' },
    { key: 'barcode', label: 'Barcode' },
    { key: 'notes', label: 'Notes' },
    { key: 'add_ons', label: 'Add Ons' },
    { key: 'tags', label: 'Tags' },
    { key: 'channels', label: 'Channels' },
    { key: 'availability', label: 'Availability' },
];
exports.initialProductConfigurationValues = {
    name: "",
    channel: "",
};
exports.validationConfig = {
    name: {
        required: true,
        minLength: 2,
        maxLength: 50,
    },
    channel: {
        required: true,
    },
};
exports.channelOptions = [
    { key: "GOOGLE", label: "Google" },
    { key: "SHOPIFY", label: "Shopify" },
    { key: "FACEBOOK", label: "Facebook" },
    { key: "ARCH_STORE", label: "Arch Store" },
    { key: "SIMPHONY", label: "Simphony" },
    { key: "STS", label: "STS" },
    { key: "SALES_LAYER", label: "Sales Layer" },
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useActionButtons = void 0;
var react_1 = require("react");
var react_router_dom_1 = require("react-router-dom");
var routes_1 = require("../../../../../../constants/routes");
function useActionButtons() {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var actionButtons = (0, react_1.useMemo)(function () { return [
        {
            iconClassname: "solar:add-circle-bold",
            label: "Add Quote",
            onClick: function () {
                navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.QUOTE_DETAILS_ROUTE));
            },
        },
    ]; }, []);
    return (0, react_1.useMemo)(function () { return ({
        actionButtons: actionButtons,
    }); }, [actionButtons]);
}
exports.useActionButtons = useActionButtons;

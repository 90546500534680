"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.IPermissions = void 0;
var IPermissions;
(function (IPermissions) {
    IPermissions["CUSTOMERS"] = "CUSTOMERS";
    IPermissions["BRANCHES"] = "BRANCHES";
    IPermissions["GENERAL_SETTINGS"] = "GENERAL_SETTINGS";
    IPermissions["TRADING_SETTINGS"] = "TRADING_SETTINGS";
    IPermissions["DELIVERY_SETTINGS"] = "DELIVERY_SETTINGS";
    IPermissions["VOUCHERS"] = "VOUCHERS";
    IPermissions["SUBSCRIPTIONS"] = "SUBSCRIPTIONS";
    IPermissions["DEALS"] = "DEALS";
    IPermissions["PACKAGES"] = "PACKAGES";
    IPermissions["CHANNELS"] = "CHANNELS";
    IPermissions["INTEGRATIONS"] = "INTEGRATIONS";
    IPermissions["WORKFLOWS"] = "WORKFLOWS";
    IPermissions["BRANCH_GROUPS"] = "BRANCH_GROUPS";
    IPermissions["BASKETS"] = "BASKETS";
    IPermissions["USERS"] = "USERS";
    IPermissions["REDEMPTIONS"] = "REDEMPTIONS";
    IPermissions["DOCUMENTS"] = "DOCUMENTS";
    IPermissions["ORDERS"] = "ORDERS";
    IPermissions["PARCELS"] = "PARCELS";
    IPermissions["LOYALTY"] = "LOYALTY";
    IPermissions["PRODUCT_CATALOG"] = "PRODUCT_CATALOG";
    IPermissions["PRODUCT_CATEGORIES"] = "PRODUCT_CATEGORIES";
    IPermissions["PRODUCT_ATTRIBUTES"] = "PRODUCT_ATTRIBUTES";
    IPermissions["PRODUCT_FAMILIES"] = "PRODUCT_FAMILIES";
    IPermissions["PRODUCT_TYPES"] = "PRODUCT_TYPES";
    IPermissions["PAYMENTS"] = "PAYMENTS";
    IPermissions["DRIVERS"] = "DRIVERS";
    IPermissions["WAYBILL"] = "WAYBILL";
    IPermissions["EXCLUDED_PRODUCT_CATALOG"] = "EXCLUDED_PRODUCT_CATALOG";
    IPermissions["STORE_FRONT"] = "STORE_FRONT";
    IPermissions["CONDIMENTS"] = "CONDIMENTS";
    IPermissions["MENU_CONCEPTS"] = "MENU_CONCEPTS";
    IPermissions["GIFTING"] = "GIFTING";
    IPermissions["ADVANCED_ANALYTICS"] = "ADVANCED_ANALYTICS";
    IPermissions["CUSTOMER_SEGMENTS"] = "CUSTOMER_SEGMENTS";
    IPermissions["MANAGE_MARKET_PLACE"] = "MANAGE_MARKET_PLACE";
    IPermissions["MARKETPLACES"] = "MARKETPLACES";
    IPermissions["SUPPLIERS"] = "SUPPLIERS";
    IPermissions["PRODUCT_REQUESTS"] = "PRODUCT_REQUESTS";
})(IPermissions = exports.IPermissions || (exports.IPermissions = {}));

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.Suppliers = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var tabel_cell_1 = require("../../../../../../components/tabel_cell");
var react_router_dom_1 = require("react-router-dom");
var react_redux_1 = require("react-redux");
var react_2 = require("react");
var console_1 = require("../../../../../../reducers/slices/console");
var main_1 = require("../../../../../../reducers/slices/main");
var copy_text_1 = require("../../../../../../components/copy_text");
var tableContext_1 = require("../../../../../../contexts/tableContext");
var table_bottom_content_1 = require("../../../../../../components/table/tableBottomContent/table_bottom_content");
var useColumns_1 = require("./hooks/useColumns");
var table_top_content_1 = require("../../../../../../components/table/tableTopContent/table_top_content");
var text_1 = require("../../../../../../controls/text");
var routes_1 = require("../../../../../../constants/routes");
var get_all_supplier_requets_thunk_1 = require("../../../../../../reducers/slices/supplier_requests/thunks/get_all_supplier_requets_thunk");
var remove_supplier_request_1 = require("../../../../../../api/calls/supplier-requests/remove_supplier_request");
var approve_or_decline_supplier_request_1 = require("../../../../../../api/calls/supplier-requests/approve_or_decline_supplier_request");
var formatting_1 = require("lets-trade-utils/lib/utils/formatting");
var Suppliers = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.userState; }), client_token = _a.client_token, user = _a.user;
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var _b = (0, react_redux_1.useSelector)(function (state) { return state.supplierRequestState; }), supplier_requests = _b.supplier_requests, pagination = _b.pagination, loading = _b.loading;
    var _c = (0, useColumns_1.useColumns)(), columns = _c.columns, excludeSortColumns = _c.excludeSortColumns, headerColumns = _c.headerColumns, initialSortDescriptor = _c.initialSortDescriptor, onVisibleColumnsChanged = _c.onVisibleColumnsChanged, setTableColumnConfig = _c.setTableColumnConfig, tableColumnConfig = _c.tableColumnConfig, visibleColumns = _c.visibleColumns;
    (0, react_2.useEffect)(function () {
        dispatch((0, console_1.setPageTitle)("Supplier Management"));
    }, []);
    (0, react_2.useEffect)(function () {
        if (client_token) {
            dispatch((0, get_all_supplier_requets_thunk_1.get_all_supplier_requests_thunk)(__assign({}, pagination)));
        }
    }, [client_token]);
    var handleEdit = function (supplier) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.SUPPLIERS_DETAILS_ROUTE, "/").concat(supplier === null || supplier === void 0 ? void 0 : supplier._id), { state: { data: supplier } });
                    if (!((supplier === null || supplier === void 0 ? void 0 : supplier.status) === "PENDING")) return [3 /*break*/, 2];
                    return [4 /*yield*/, (0, approve_or_decline_supplier_request_1.update_supplier_request_details)({
                            _id: supplier === null || supplier === void 0 ? void 0 : supplier._id,
                            clientSupplierRequest: {
                                status: "IN REVIEW",
                            },
                        })];
                case 1:
                    _a.sent();
                    _a.label = 2;
                case 2: return [2 /*return*/];
            }
        });
    }); };
    var handleDelete = function (supplier) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            if (user.role !== "ADMIN") {
                dispatch((0, main_1.setContentModal)(null));
                dispatch((0, main_1.setMessageModal)({
                    title: "You are not authorized to perform this action!",
                    messages: ["Please contact an admin to perform this action."],
                }));
                return [2 /*return*/];
            }
            dispatch((0, main_1.setContentModal)({
                title: "Confirm you are deleting ".concat(supplier === null || supplier === void 0 ? void 0 : supplier.supplier_name, " supplier"),
                content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 flex-col items-center" }, { children: [(0, jsx_runtime_1.jsx)(text_1.TextControl, { value: "Are you sure you want to delete this supplier?" }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 justify-center" }, { children: [(0, jsx_runtime_1.jsx)(react_1.Button, __assign({ onClick: function () { return dispatch((0, main_1.setContentModal)(null)); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ className: "bg-primary text-white", onClick: function () { return __awaiter(void 0, void 0, void 0, function () {
                                        var response;
                                        return __generator(this, function (_a) {
                                            switch (_a.label) {
                                                case 0: return [4 /*yield*/, (0, remove_supplier_request_1.remove_supplier_request)({ _id: supplier === null || supplier === void 0 ? void 0 : supplier._id })];
                                                case 1:
                                                    response = _a.sent();
                                                    if (response.success) {
                                                        dispatch((0, main_1.setContentModal)(null));
                                                        dispatch((0, get_all_supplier_requets_thunk_1.get_all_supplier_requests_thunk)(__assign({}, pagination)));
                                                    }
                                                    else {
                                                        dispatch((0, main_1.setContentModal)(null));
                                                        dispatch((0, main_1.setMessageModal)({ title: "Something went wrong when deleting supplier!", messages: [response.message] }));
                                                    }
                                                    return [2 /*return*/];
                                            }
                                        });
                                    }); } }, { children: "Continue" }))] }))] }))),
            }));
            return [2 /*return*/];
        });
    }); };
    var getSuppliers = function (query) { return __awaiter(void 0, void 0, void 0, function () {
        return __generator(this, function (_a) {
            (function () { });
            return [2 /*return*/];
        });
    }); };
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return handleEdit(cellValue); },
        },
        {
            key: "edit",
            onClick: function () { return handleEdit(cellValue); },
        },
        {
            key: "delete",
            onClick: function () { return handleDelete(cellValue); },
        },
    ]; };
    var renderCellViews = function (cellValue) {
        return [
            {
                key: "supplier_name",
                content: (0, jsx_runtime_1.jsx)(copy_text_1.CopyText, __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
            },
            {
                key: "total_products",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue ? cellValue : "No Products Yet" })),
            },
            {
                key: "last_activity",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: (user === null || user === void 0 ? void 0 : user.last_signin) ? (0, formatting_1.formatDateTime)(new Date(user === null || user === void 0 ? void 0 : user.last_signin)) : "No Date" }))),
            },
            {
                key: "marketplace_name",
                content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })),
            },
            {
                key: "status",
                content: ((0, jsx_runtime_1.jsx)(react_1.Chip, __assign({ className: "text-nowrap text-xs capitalize text-default-foreground", color: cellValue === "APPROVED" ? "success" : cellValue === "IN REVIEW" ? "warning" : "danger", size: "md", variant: "flat" }, { children: (0, jsx_runtime_1.jsx)("span", __assign({ className: "px-1 text-default-800" }, { children: cellValue })) }))),
            },
        ];
    };
    return ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsx)(tableContext_1.TableContextProvider, __assign({ columns: columns, defaultQueryParams: pagination, excludeSortColumns: excludeSortColumns, filterActions: [], headerColumns: headerColumns, initialSortDescriptor: initialSortDescriptor, onPageChange: getSuppliers, onVisibleColumnsChanged: onVisibleColumnsChanged, setTableConfig: setTableColumnConfig, tableColumnConfig: tableColumnConfig, pagination: pagination, visibleColumns: visibleColumns }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex w-[400px] items-center gap-2 mb-4" }, { children: [(0, jsx_runtime_1.jsx)(text_1.TextControl, { value: "Supplier Management", className: "text-2xl font-[700] leading-[32px]" }), (0, jsx_runtime_1.jsx)(react_1.Chip, __assign({ className: "hidden items-center text-default-500 sm:flex", size: "sm", variant: "flat" }, { children: supplier_requests === null || supplier_requests === void 0 ? void 0 : supplier_requests.length }))] })), (0, jsx_runtime_1.jsxs)(react_1.Table, __assign({ isHeaderSticky: true, "aria-label": "Products", bottomContent: (0, jsx_runtime_1.jsx)(table_bottom_content_1.TableBottomContent, {}), bottomContentPlacement: "outside", classNames: {
                            td: "before:bg-transparent",
                        }, className: "overflow-hidden", 
                        // selectedKeys={selectedTableCells}
                        selectionMode: "multiple", sortDescriptor: initialSortDescriptor, topContent: (0, jsx_runtime_1.jsx)(table_top_content_1.TableTopContent, {}), topContentPlacement: "outside" }, { children: [(0, jsx_runtime_1.jsx)(react_1.TableHeader, __assign({ columns: headerColumns }, { children: function (column) { return ((0, jsx_runtime_1.jsx)(react_1.TableColumn, __assign({ align: column.uid === "actions" ? "end" : "start", className: (0, react_1.cn)([column.uid === "actions" ? "flex items-center justify-end px-[20px]" : ""]) }, { children: column.name }), column.uid)); } })), (0, jsx_runtime_1.jsx)(react_1.TableBody, __assign({ isLoading: false, emptyContent: "This table is empty.", items: supplier_requests, loadingContent: (0, jsx_runtime_1.jsx)(react_1.Spinner, { label: "Loading..." }) }, { children: function (item) {
                                    return ((0, jsx_runtime_1.jsx)(react_1.TableRow, { children: function (columnKey) { return ((0, jsx_runtime_1.jsx)(react_1.TableCell, { children: (0, jsx_runtime_1.jsx)(tabel_cell_1.TableCellComponent, { columnRecord: item, columnKey: columnKey, renderCellViews: renderCellViews, rowActions: rowActions }) })); } }, item === null || item === void 0 ? void 0 : item._id));
                                } }))] }))] })) })) }));
};
exports.Suppliers = Suppliers;

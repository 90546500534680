"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableBottomContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("@nextui-org/react");
var paginationContext_1 = require("../../../contexts/paginationContext");
function TableBottomContent() {
    var _a = (0, paginationContext_1.usePaginationContext)(), page = _a.currentPage, onNextPage = _a.onNextPage, onPreviousPage = _a.onPreviousPage, pagination = _a.pagination, pages = _a.pages, selectedKeys = _a.selectedKeys, selectionMode = _a.selectionMode, setPage = _a.setPage;
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col items-center justify-between sm:flex-row" }, { children: [(0, jsx_runtime_1.jsx)(react_1.Pagination, { isCompact: true, showControls: true, showShadow: true, color: "primary", page: page, total: pages, onChange: setPage }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center justify-end gap-6" }, { children: [selectionMode === "multiple" && ((0, jsx_runtime_1.jsx)("span", __assign({ className: "text-small text-default-400" }, { children: selectedKeys === "all" ? "All items selected" : "".concat(Array.from(selectedKeys).length, " of ").concat(pagination.size, " selected") }))), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-3" }, { children: [(0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isDisabled: page === 1, size: "sm", variant: "flat", onPress: onPreviousPage }, { children: "Previous" })), (0, jsx_runtime_1.jsx)(react_1.Button, __assign({ isDisabled: page === pages, size: "sm", variant: "flat", onPress: onNextPage }, { children: "Next" }))] }))] }))] })));
}
exports.TableBottomContent = TableBottomContent;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.TableTopContent = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_solid_svg_icons_1 = require("@fortawesome/pro-solid-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("@iconify/react");
var react_3 = require("@nextui-org/react");
var filtersContext_1 = require("../../../contexts/filtersContext");
var paginationContext_1 = require("../../../contexts/paginationContext");
var tableContext_1 = require("../../../contexts/tableContext");
function TableTopContent() {
    var _a = (0, filtersContext_1.useFiltersContext)(), filterActions = _a.filterActions, isFiltersPopoverOpen = _a.isFiltersPopoverOpen, onApplyFilters = _a.onApplyFilters, onClearFilters = _a.onClearFilters, onCloseFilterPopover = _a.onCloseFilterPopover, onOpenFilterPopover = _a.onOpenFilterPopover;
    var setPage = (0, paginationContext_1.usePaginationContext)().setPage;
    var _b = (0, tableContext_1.useTableContext)(), actionButtons = _b.actionButtons, allowSorting = _b.allowSorting, columns = _b.columns, headerColumns = _b.headerColumns, onSearchChanged = _b.onSearchChanged, onSortChanged = _b.onSortChanged, onVisibleColumnsChanged = _b.onVisibleColumnsChanged, setTableConfig = _b.setTableConfig, sortDescriptor = _b.sortDescriptor, tableColumnConfig = _b.tableColumnConfig, visibleColumns = _b.visibleColumns;
    var handleApplyFilters = (0, react_1.useCallback)(function () {
        onApplyFilters();
    }, [onApplyFilters]);
    var handleClearFilters = (0, react_1.useCallback)(function () {
        onClearFilters();
    }, [onClearFilters]);
    var handleOpenChanged = (0, react_1.useCallback)(function () {
        if (!isFiltersPopoverOpen) {
            onOpenFilterPopover();
        }
        else {
            onCloseFilterPopover();
        }
    }, [isFiltersPopoverOpen, onCloseFilterPopover, onOpenFilterPopover]);
    var handleSearch = (0, react_1.useCallback)(function (searchString) {
        onSearchChanged(searchString);
        if (searchString === "") {
            setPage(1);
        }
    }, [onSearchChanged, setPage]);
    var handleSortChanged = (0, react_1.useCallback)(function (item) { return function () {
        onSortChanged({
            column: item.uid,
            direction: sortDescriptor.direction === "ascending" ? "descending" : "ascending",
        });
    }; }, []);
    var handleVisibleColumnsChanged = (0, react_1.useCallback)(function (keys) {
        console.log(keys);
        var visibleColumns = [];
        var keysArray = Array.from(keys);
        for (var i = 0; i < keysArray.length; i++) {
            visibleColumns.push(keysArray[i]);
        }
        setTableConfig(__assign(__assign({}, tableColumnConfig), { visibleColumns: visibleColumns }));
        onVisibleColumnsChanged(keys);
    }, [onVisibleColumnsChanged, setTableConfig, tableColumnConfig]);
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center overflow-auto" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex w-full justify-between items-center gap-3" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center gap-4" }, { children: [(0, jsx_runtime_1.jsx)(react_3.Input, { className: "min-w-[200px]", endContent: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_solid_svg_icons_1.faSearch, className: "text-default-400", width: 16 }), placeholder: "Search", size: "sm", onValueChange: handleSearch }), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_3.Popover, __assign({ isOpen: isFiltersPopoverOpen, onOpenChange: handleOpenChanged, placement: "bottom" }, { children: [(0, jsx_runtime_1.jsx)(react_3.PopoverTrigger, { children: (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", size: "sm", startContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:tuning-2-linear", width: 16 }) }, { children: "Filter" })) }), (0, jsx_runtime_1.jsx)(react_3.PopoverContent, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-80 p-4" }, { children: [filterActions.map(function (_a) {
                                                    var items = _a.items, label = _a.label, onChange = _a.onChange, value = _a.value;
                                                    return ((0, jsx_runtime_1.jsx)(react_3.RadioGroup, __assign({ label: label, onValueChange: onChange, size: "sm", value: value }, { children: items.map(function (_a) {
                                                            var label = _a.label, value = _a.value;
                                                            return ((0, jsx_runtime_1.jsx)(react_3.Radio, __assign({ value: value }, { children: label }), value));
                                                        }) }), label));
                                                }), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "w-full flex justify-end gap-4 mt-4" }, { children: [(0, jsx_runtime_1.jsx)(react_3.Button, __assign({ color: "primary", onClick: handleClearFilters, size: "sm", variant: "light" }, { children: "Clear" })), (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ color: "primary", onClick: handleApplyFilters, size: "sm" }, { children: "Apply" }))] }))] })) })] })) }), allowSorting && ((0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_3.Dropdown, { children: [(0, jsx_runtime_1.jsx)(react_3.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", size: "sm", startContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:sort-linear", width: 16 }) }, { children: "Sort" })) }), (0, jsx_runtime_1.jsx)(react_3.DropdownMenu, __assign({ "aria-label": "Sort", items: headerColumns.filter(function (_a) {
                                            var sortable = _a.sortable;
                                            return sortable === true;
                                        }) }, { children: function (item) { return ((0, jsx_runtime_1.jsx)(react_3.DropdownItem, __assign({ onPress: handleSortChanged(item) }, { children: item.name }), item.uid)); } }))] }) })), (0, jsx_runtime_1.jsx)("div", { children: (0, jsx_runtime_1.jsxs)(react_3.Dropdown, __assign({ closeOnSelect: false }, { children: [(0, jsx_runtime_1.jsx)(react_3.DropdownTrigger, { children: (0, jsx_runtime_1.jsx)(react_3.Button, __assign({ className: "bg-default-100 text-default-800", size: "sm", startContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { className: "text-default-400", icon: "solar:sort-horizontal-linear", width: 16 }) }, { children: "Columns" })) }), (0, jsx_runtime_1.jsx)(react_3.DropdownMenu, __assign({ disallowEmptySelection: true, "aria-label": "Columns", items: columns.filter(function (c) { return !["actions"].includes(c.uid); }), selectedKeys: visibleColumns, selectionMode: "multiple", onSelectionChange: handleVisibleColumnsChanged }, { children: function (item) { return (0, jsx_runtime_1.jsx)(react_3.DropdownItem, { children: item.name }, item.uid); } }))] })) })] })), (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex justify-end gap-4" }, { children: actionButtons === null || actionButtons === void 0 ? void 0 : actionButtons.map(function (button, index) { return ((0, jsx_runtime_1.jsx)(react_3.Button, __assign({ color: "primary", onClick: button.onClick, endContent: (0, jsx_runtime_1.jsx)(react_2.Icon, { icon: button.iconClassname, width: 20 }) }, { children: button.label }), index)); }) }))] })) })));
}
exports.TableTopContent = TableTopContent;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("@nextui-org/react");
var MultiTextInput = function (_a) {
    var options = _a.options, callback = _a.callback;
    var _b = (0, react_1.useState)(Array.isArray(options) ? options : []), values = _b[0], setValues = _b[1];
    var handleAddValue = function () {
        setValues(__spreadArray(__spreadArray([], values, true), [""], false));
        callback(__spreadArray(__spreadArray([], values, true), [""], false));
    };
    var handleRemoveValue = function (index) {
        var newValues = values.filter(function (_, i) { return i !== index; });
        setValues(newValues);
        callback(newValues);
    };
    var handleValueChange = function (index, newValue) {
        var newValues = values.map(function (value, i) { return (i === index ? newValue : value); });
        setValues(newValues);
        callback(newValues.filter(function (v) { return v.trim(); }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "space-y-2" }, { children: [values.map(function (value, index) { return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-2" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Input, { value: value, maxLength: 50, onChange: function (e) { return handleValueChange(index, e.target.value); }, placeholder: "Enter value", className: "flex-1" }), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ variant: "light", type: "button", onClick: function () { return handleRemoveValue(index); }, className: "shrink-0" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faTrash }) }))] }), index)); }), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ type: "button", variant: "light", onClick: handleAddValue, className: "w-full" }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { icon: pro_light_svg_icons_1.faPlus }) }))] })));
};
exports.default = MultiTextInput;

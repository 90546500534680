"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setTablePagination = exports.setIsLoading = exports.setSelectedProductConfiguration = exports.setProductConfigurations = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../../constants/pages");
var initialState = {
    productConfigurations: [],
    selectedProductConfiguration: null,
    pagination: pages_1.initialPaginationValues,
    loading: false
};
var productConfigurationSlice = (0, toolkit_1.createSlice)({
    name: 'productConfiguration',
    initialState: initialState,
    reducers: {
        setProductConfigurations: function (state, action) {
            state.productConfigurations = action.payload;
        },
        setSelectedProductConfiguration: function (state, action) {
            state.selectedProductConfiguration = action.payload;
        },
        setIsLoading: function (state, action) {
            state.loading = action.payload;
        },
        setTablePagination: function (state, action) {
            state.pagination = action.payload;
        },
    },
});
exports.setProductConfigurations = (_a = productConfigurationSlice.actions, _a.setProductConfigurations), exports.setSelectedProductConfiguration = _a.setSelectedProductConfiguration, exports.setIsLoading = _a.setIsLoading, exports.setTablePagination = _a.setTablePagination;
exports.default = productConfigurationSlice.reducer;

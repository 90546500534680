"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.SUPPLIER_CURRENT_STATUS = exports.INITIAL_VISIBLE_COLUMNS = void 0;
exports.INITIAL_VISIBLE_COLUMNS = ["supplier_name", "number_of_products", "last_activity", "status", "actions"];
exports.SUPPLIER_CURRENT_STATUS = [
    { label: "Pending", value: "PENDING" },
    { label: "In Review", value: "IN REVIEW" },
    { label: "Approved", value: "APPROVED" },
    { label: "Declined", value: "DECLINED" },
];

"use strict";
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = void 0;
var react_1 = require("react");
var react_redux_1 = require("react-redux");
function useRenderCellViews() {
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        var commonTextStyles = "text-small capitalize text-default-foreground";
        var nowrapTextStyles = "text-nowrap text-small capitalize text-default-foreground";
        return [];
    }, [client]);
    return (0, react_1.useMemo)(function () { return ({ renderCellViews: renderCellViews }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = exports.PriceCellView = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_redux_1 = require("react-redux");
var react_2 = require("@nextui-org/react");
var formatting_1 = require("lets-trade-utils/lib/utils/formatting");
function EmailCellView(_a) {
    var cellValue = _a.cellValue;
    if (!cellValue) {
        return (0, jsx_runtime_1.jsx)("div", { children: "-" });
    }
    return (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small text-default-foreground" }, { children: cellValue.toLowerCase() }));
}
function PriceCellView(_a) {
    var _b;
    var cellValue = _a.cellValue, client = _a.client;
    return ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: (0, formatting_1.formatMoney)({
            symbol: client === null || client === void 0 ? void 0 : client.currency_symbol,
            value: (_b = cellValue === null || cellValue === void 0 ? void 0 : cellValue.toString()) !== null && _b !== void 0 ? _b : "0",
            symbolSeparation: true,
        }) })));
}
exports.PriceCellView = PriceCellView;
function StatusCellView(_a) {
    var cellValue = _a.cellValue;
    var color = (0, react_1.useMemo)(function () {
        if ([
            "ACTIVE",
            "COMPLETED",
            "FUlFILLED",
            "PROCESSED",
            "ORDERED",
            "READY",
            "ARRIVED",
            "DELIVERED",
            "ONLINE",
            "AVAILABLE",
            "COMPLETE",
            "SUCCESS",
            "ACCEPTED",
        ].includes(cellValue)) {
            return "success";
        }
        else if (["INACTIVE", "CANCELLED", "OFFLINE"].includes(cellValue)) {
            return "default";
        }
        else if (["UPDATED", "OPEN", "RETURNED", "PACKED", "VIEWED"].includes(cellValue)) {
            return "secondary";
        }
        else if (["SUSPENDED", "ABANDONED", "RETURNED-BY-CUSTOMER", "DECLINED", "EXPIRED"].includes(cellValue)) {
            return "danger";
        }
        else if ([
            "AWAITING-SIGNUP",
            "PROCESSING",
            "CHECKED-OUT",
            "POS-ORDERED",
            "COLLECTED",
            "DELIVERY-READY",
            "PARTIALLY-DELIVERED",
            "AWAITING-EMAIL-CONFIRMATION",
            "SENT",
            "PENDING",
        ].includes(cellValue)) {
            return "warning";
        }
        return "default";
    }, [cellValue]);
    return ((0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "text-nowrap text-xs capitalize text-default-foreground", color: color, size: "md", variant: "flat" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-1" }, { children: cellValue })) })));
}
function useRenderCellViews() {
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        return [
            { key: "customer", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "email", content: (0, jsx_runtime_1.jsx)(EmailCellView, { cellValue: cellValue }) },
            { key: "cell_number", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "createdAt", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: (0, formatting_1.formatDateTime)(new Date(cellValue)) })) },
            { key: "total", content: (0, jsx_runtime_1.jsx)(PriceCellView, { cellValue: cellValue, client: client }) },
            { key: "branch_name", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "type", content: (0, jsx_runtime_1.jsx)("div", __assign({ className: "text-nowrap text-small capitalize text-default-foreground" }, { children: cellValue })) },
            { key: "status", content: (0, jsx_runtime_1.jsx)(StatusCellView, { cellValue: cellValue }) },
        ];
    }, [client]);
    return (0, react_1.useMemo)(function () { return ({
        renderCellViews: renderCellViews,
    }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;

"use strict";
var _a;
Object.defineProperty(exports, "__esModule", { value: true });
exports.setPagination = exports.setLoading = exports.setSupplierProducts = exports.setSupplierRequests = void 0;
var toolkit_1 = require("@reduxjs/toolkit");
var pages_1 = require("../../../constants/pages");
var initialState = {
    supplier_requests: [],
    supplier_products: [],
    loading: false,
    pagination: pages_1.initialPaginationValues,
};
var slice = (0, toolkit_1.createSlice)({
    name: "supplier_requests",
    initialState: initialState,
    reducers: {
        setSupplierRequests: function (state, action) {
            state.supplier_requests = action.payload;
        },
        setSupplierProducts: function (state, action) {
            state.supplier_products = action.payload;
        },
        setPagination: function (state, action) {
            state.pagination = action.payload;
        },
        setLoading: function (state, action) {
            state.loading = action.payload;
        },
    },
});
exports.setSupplierRequests = (_a = slice.actions, _a.setSupplierRequests), exports.setSupplierProducts = _a.setSupplierProducts, exports.setLoading = _a.setLoading, exports.setPagination = _a.setPagination;
exports.default = slice.reducer;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRenderCellViews = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
function useRenderCellViews() {
    var client = (0, react_redux_1.useSelector)(function (state) { return state.clientState; }).client;
    var renderCellViews = (0, react_1.useCallback)(function (cellValue) {
        var commonTextStyles = "text-small capitalize text-default-foreground";
        var nowrapTextStyles = "text-nowrap text-small capitalize text-default-foreground";
        return [
            {
                key: "attributes",
                content: ((0, jsx_runtime_1.jsx)("div", __assign({ className: "text-small capitalize text-default-foreground" }, { children: (cellValue === null || cellValue === void 0 ? void 0 : cellValue.map) &&
                        (cellValue === null || cellValue === void 0 ? void 0 : cellValue.map(function (attr, i) {
                            return ((0, jsx_runtime_1.jsx)(react_2.Chip, __assign({ className: "text-nowrap text-xs capitalize text-default-foreground", color: "default", size: "md", variant: "flat" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "p-1" }, { children: attr.name })) }), i));
                        })) }))),
            },
        ];
    }, [client]);
    return (0, react_1.useMemo)(function () { return ({ renderCellViews: renderCellViews }); }, [renderCellViews]);
}
exports.useRenderCellViews = useRenderCellViews;

"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
Object.defineProperty(exports, "__esModule", { value: true });
exports.useRowActions = void 0;
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var react_2 = require("@nextui-org/react");
var react_redux_1 = require("react-redux");
var react_router_dom_1 = require("react-router-dom");
var delete_product_attribute_1 = require("../../../../../../api/calls/product/product_attributes/delete/delete_product_attribute");
var routes_1 = require("../../../../../../constants/routes");
var main_1 = require("../../../../../../reducers/slices/main");
var get_product_attributes_thunk_1 = require("../../../../../../reducers/slices/products/product_attribute/thunks/get_product_attributes_thunk");
var useRowActions = function () {
    var navigate = (0, react_router_dom_1.useNavigate)();
    var dispatch = (0, react_redux_1.useDispatch)();
    var _a = (0, react_redux_1.useSelector)(function (state) { return state.productState; }), products = _a.products, pagination = _a.pagination;
    var _b = (0, react_1.useState)(new Set([])), selectedTableCells = _b[0], setSelectedTableCells = _b[1];
    var onSelectionChange = function (keys) {
        if (keys === "all") {
            setSelectedTableCells(keys);
            return;
        }
        var resultKeys = new Set(keys);
        var currentKeys = selectedTableCells === "all" ? new Set(products.map(function (item) { return String(item._id); })) : selectedTableCells;
        currentKeys.forEach(function (key) {
            if (!products.some(function (item) { return String(item._id) === key; })) {
                resultKeys.add(key);
            }
        });
        setSelectedTableCells(resultKeys);
    };
    var rowActions = function (cellValue) { return [
        {
            key: "view",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_ATTRIBUTES_DETAILS_ROUTE, "/").concat(cellValue._id)); },
        },
        {
            key: "edit",
            onClick: function () { return navigate("".concat(routes_1.CONSOLE_ROUTE).concat(routes_1.PRODUCT_ATTRIBUTES_DETAILS_ROUTE, "/").concat(cellValue._id)); },
        },
        {
            key: "delete",
            onClick: function () {
                return dispatch((0, main_1.setContentModal)({
                    title: "Confirm deletion of ".concat(cellValue === null || cellValue === void 0 ? void 0 : cellValue.title, " product"),
                    content: ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex flex-col justify-start pt-4" }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-lg font-normal mb-4" }, { children: "Are you sure you want to delete this product?" })), (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex gap-4 pt-4 justify-start" }, { children: [(0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "danger", variant: "light", onPress: function () { return dispatch((0, main_1.setContentModal)(null)); } }, { children: "Cancel" })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ color: "primary", onPress: function () { return handleDelete(cellValue === null || cellValue === void 0 ? void 0 : cellValue._id); } }, { children: "Continue" }))] }))] }))),
                }));
            },
        },
    ]; };
    var handleDelete = function (_id) { return __awaiter(void 0, void 0, void 0, function () {
        var response, error_1;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    _a.trys.push([0, 2, , 3]);
                    return [4 /*yield*/, (0, delete_product_attribute_1.delete_product_attribute)({ _id: _id })];
                case 1:
                    response = _a.sent();
                    if (response.success) {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Success...",
                            messages: [response.message],
                        }));
                        dispatch((0, main_1.setContentModal)(null));
                        dispatch((0, get_product_attributes_thunk_1.get_product_attributes_thunk)(__assign({}, pagination)));
                    }
                    else {
                        dispatch((0, main_1.setMessageModal)({
                            title: "Something went wrong when deleting the product!",
                            messages: [response.message],
                        }));
                    }
                    return [3 /*break*/, 3];
                case 2:
                    error_1 = _a.sent();
                    dispatch((0, main_1.setMessageModal)({
                        title: "Error",
                        messages: ["An unexpected error occurred"],
                    }));
                    return [3 /*break*/, 3];
                case 3: return [2 /*return*/];
            }
        });
    }); };
    return (0, react_1.useMemo)(function () { return ({ rowActions: rowActions, selectedTableCells: selectedTableCells, onSelectionChange: onSelectionChange }); }, [rowActions]);
};
exports.useRowActions = useRowActions;

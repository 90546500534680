"use strict";
var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
var __spreadArray = (this && this.__spreadArray) || function (to, from, pack) {
    if (pack || arguments.length === 2) for (var i = 0, l = from.length, ar; i < l; i++) {
        if (ar || !(i in from)) {
            if (!ar) ar = Array.prototype.slice.call(from, 0, i);
            ar[i] = from[i];
        }
    }
    return to.concat(ar || Array.prototype.slice.call(from));
};
Object.defineProperty(exports, "__esModule", { value: true });
var jsx_runtime_1 = require("react/jsx-runtime");
var react_1 = require("react");
var pro_light_svg_icons_1 = require("@fortawesome/pro-light-svg-icons");
var react_fontawesome_1 = require("@fortawesome/react-fontawesome");
var react_2 = require("@nextui-org/react");
var ProductAttributesInput = function (_a) {
    var _b = _a.initialAttributes, initialAttributes = _b === void 0 ? [] : _b, _c = _a.attributeList, attributeList = _c === void 0 ? [] : _c, onChange = _a.onChange, children = _a.children;
    var _d = (0, react_1.useState)(initialAttributes), attributes = _d[0], setAttributes = _d[1];
    attributeList = attributeList
        .filter(function (attr) { return !attributes.some(function (initial) { return (initial === null || initial === void 0 ? void 0 : initial._id) === (attr === null || attr === void 0 ? void 0 : attr._id); }); })
        .map(function (_a) {
        var client_id = _a.client_id, rest = __rest(_a, ["client_id"]);
        return __assign(__assign({}, rest), { value: rest.type === "LIST" ? rest === null || rest === void 0 ? void 0 : rest.options : rest === null || rest === void 0 ? void 0 : rest.default_value });
    });
    (0, react_1.useEffect)(function () {
        if (!attributes.length)
            setAttributes(initialAttributes);
    }, [attributes, initialAttributes]);
    var handleAddAttribute = function (value) {
        var selectedAttribute = attributeList.find(function (i) { return i._id === value._id; });
        var newAttributes = __spreadArray(__spreadArray([], attributes, true), [selectedAttribute], false).map(function (_a) {
            var value = _a.value, rest = __rest(_a, ["value"]);
            return rest;
        });
        setAttributes(newAttributes);
        onChange(newAttributes);
    };
    var handleRemoveAttribute = function (_id) {
        var newAttributes = attributes.filter(function (_, i) { return _._id !== _id; });
        setAttributes(newAttributes);
        onChange(newAttributes);
    };
    var handleAttributeChange = function (index, field, newValue) {
        var newAttributes = attributes === null || attributes === void 0 ? void 0 : attributes.map(function (attr, i) {
            var _a;
            if (i === index) {
                return __assign(__assign({}, attr), (_a = {}, _a[field] = newValue, _a.value = undefined, _a));
            }
            return attr;
        });
        setAttributes(newAttributes);
        onChange(newAttributes.filter(function (attr) { return (attr === null || attr === void 0 ? void 0 : attr.name.trim()) || (attr === null || attr === void 0 ? void 0 : attr.value.trim()); }));
    };
    return ((0, jsx_runtime_1.jsxs)("div", __assign({ className: "" }, { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "pb-4" }, { children: (0, jsx_runtime_1.jsx)(react_2.Select, __assign({ label: "Add attribute to family", className: "w-80", items: attributeList, onChange: function (e) {
                        var selectedId = e.target.value;
                        var selectedOption = attributeList.find(function (opt) { return opt._id === selectedId; });
                        handleAddAttribute(__assign({}, selectedOption));
                    } }, { children: function (attribute) { return (0, jsx_runtime_1.jsx)(react_2.SelectItem, { children: attribute.name }, attribute._id); } })) })), attributes === null || attributes === void 0 ? void 0 : attributes.map(function (attr, index) { return ((0, jsx_runtime_1.jsxs)("div", { children: [(0, jsx_runtime_1.jsx)("div", __assign({ className: "flex flex-col space-y-2 mx-4" }, { children: (0, jsx_runtime_1.jsx)("div", __assign({ className: "flex items-center gap-2" }, { children: (0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex-1 space-y-2" }, { children: [(0, jsx_runtime_1.jsxs)("div", __assign({ className: "flex items-center justify-between flex-row" }, { children: [(0, jsx_runtime_1.jsx)("h3", __assign({ className: "text-base font-semibold mb-1" }, { children: attr === null || attr === void 0 ? void 0 : attr.name })), (0, jsx_runtime_1.jsx)(react_2.Button, __assign({ isIconOnly: true, color: "danger", size: "sm", onClick: function () { return handleRemoveAttribute(attr === null || attr === void 0 ? void 0 : attr._id); } }, { children: (0, jsx_runtime_1.jsx)(react_fontawesome_1.FontAwesomeIcon, { color: "text-error", icon: pro_light_svg_icons_1.faTrash }) }))] })), (0, jsx_runtime_1.jsx)(react_2.Checkbox, __assign({ isSelected: attr === null || attr === void 0 ? void 0 : attr.is_required, onChange: function (e) { return handleAttributeChange(index, "is_required", e.target.checked); } }, { children: "Is Required" }))] })) })) })), index !== attributes.length - 1 && (0, jsx_runtime_1.jsx)(react_2.Divider, { className: "my-4" })] }, index)); })] })));
};
exports.default = ProductAttributesInput;
